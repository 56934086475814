<template>
    <parent-layout :title="'User Management ' + (isEdit ? 'Edit' : 'Create')">
        <extended-form
            :form-ready="form.ready"
            :form-data="form.data"
            :disabled="!hasAccessRole || getFetching || getSaving"
            :submit="submit"
        >
            <v-row>
                <v-col>
                    <h4>User Information</h4>
                    <v-col cols="12" class="py-0">
                        <text-field :form="form" field-key="username" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="emailAddress"
                            hide-details="auto"
                            required
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="firstName"
                            hide-details="auto"
                            required
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field :form="form" field-key="middleName" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="lastName"
                            hide-details="auto"
                            required
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field :form="form" field-key="title" hide-details="auto" />
                    </v-col>

                    <v-col>
                        <h4>Demographic Information</h4>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <date-picker-form v-model="form.data.birthDate" label="Birth Date" outlined>
                        </date-picker-form>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <drop-down
                            :form="form"
                            field-key="gender"
                            :items="['M', 'F', 'N']"
                            label="Gender"
                            outlined
                            hide-details="auto"
                            dense
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <drop-down
                            :form="form"
                            field-key="hispanicIndicator"
                            label="Hispanic Indicator"
                            :items="['Y', 'N']"
                            dense
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <auto-complete
                            :form="form"
                            field-key="primaryRaceCode"
                            label="Primary Race"
                            :items="primaryRaceCodes"
                            outlined
                            hide-details="auto"
                        />
                    </v-col>
                </v-col>
                <v-col>
                    <h4>Additional Information</h4>
                    <v-col cols="12" class="py-0">
                        <v-switch v-model="form.data.isActive" inset label="Active"></v-switch>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <date-picker-form v-model="form.data.hireDate" label="Hire Date">
                        </date-picker-form>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <date-picker-form v-model="form.data.exitDate" label="Exit Date">
                        </date-picker-form>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :disabled="!isEdit"
                            :form="form"
                            field-key="schoolYear"
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <auto-complete
                            :form="form"
                            field-key="schoolCode"
                            label="School (Code)"
                            item-text="value"
                            item-value="name"
                            :items="schoolCodes"
                            outlined
                            hide-details="auto"
                            required
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="authXAccountId"
                            label="Illuminate SSO Id"
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="staffId"
                            label="HR Staff Id"
                            hide-details="auto"
                            required
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <text-field
                            :form="form"
                            field-key="staffHRNumber"
                            label="HR Number"
                            hide-details="auto"
                        />
                    </v-col>
                </v-col>
            </v-row>
        </extended-form>
    </parent-layout>
</template>

<script>
import { ROLE_USER_MANAGEMENT_CREATE, ROLE_USER_MANAGEMENT_EDIT } from '@/helpers/security/roles'
import DatePickerForm from '@/components/form/DatePickerForm'
import ExtendedForm from '@/components/form/ExtendedForm'
import formatting from '@/helpers/form/formatting'
import { email, uuid } from '@/helpers/form/validation'
import ParentLayout from '@/components/layout/ParentLayout'
import TextField from '@/components/form/TextField'
import { mapActions, mapGetters } from 'vuex'
import { formSnackbar } from '@/helpers/snackbar'
import AutoComplete from '@/components/form/AutoComplete'
import DropDown from '@/components/form/DropDown.vue'

export default {
    components: {
        TextField,
        ParentLayout,
        DatePickerForm,
        ExtendedForm,
        AutoComplete,
        DropDown,
    },
    data: () => ({
        valid: false,
        primaryRaceCodes: [
            'American Indian or Alaska Native',
            'Asian',
            'Asian/Pacific Islander',
            'Black',
            'Black or African American',
            'Hispanic',
            'Indian/Alaskan Native',
            'Multi Race',
            'Native Hawaiian or Other Pacific Islander',
            'Not Defined',
            'White',
        ],
        schoolCodes: [],
        form: {
            data: {
                emailAddress: null,
                firstName: null,
                lastName: null,
                middleName: null,
                username: null,
                suffixName: null,
                title: null,
                staffId: null,
                staffHRNumber: null,
                id: null,
                birthDate: null,
                gender: null,
                primaryRaceCode: null,
                hispanicIndicator: null,
                hireDate: null,
                exitDate: null,
                isActive: null,
                schoolYear: null,
                schoolCode: null,
                authXAccountId: null,
            },
            rules: {
                emailAddress: [(v) => !!v || 'E-mail is required', (v) => email(v)],
                firstName: [(v) => !!v || 'First Name is required'],
                lastName: [(v) => !!v || 'Last Name is required'],
                staffId: [(v) => !!v || 'Staff Id is required'],
                schoolCode: [(v) => !!v || 'School Code is required'],
                authXAccountId: [(v) => uuid(v)],
            },
            errors: [],
            ready: false,
        },
        dateFields: ['birthDate', 'exitDate', 'hireDate'],
    }),
    computed: {
        ...mapGetters(['getDistrictId', 'getDistrictLea']),
        ...mapGetters('staff', ['getErrors', 'getFetching', 'getSaving', 'getItemById']),
        ...mapGetters({
            sites: 'site/getItems',
            hasRole: 'auth/hasRole',
        }),
        isEdit() {
            return this.$route.name === 'staff-user-edit'
        },
        getRouteId() {
            return this.$route.params.id
        },
        getData() {
            return this.getItemById(this.getRouteId)
        },
        hasAccessRole() {
            const role = this.isEdit ? ROLE_USER_MANAGEMENT_EDIT : ROLE_USER_MANAGEMENT_CREATE

            return this.hasRole(role)
        },
    },
    async mounted() {
        const formData = {}

        // Get sites to populate to School Code drop down
        await this.getSites({ lea: this.getDistrictLea })
        this.schoolCodes = this.sites.reduce((acc, currVal) => {
            acc.push({
                name: currVal['schoolCode'],
                value: currVal['schoolName'] + ' (' + currVal['schoolCode'] + ')',
            })
            return acc
        }, [])

        if (this.isEdit) {
            await this.getStaff({
                id: this.getRouteId,
                lea: this.getDistrictLea,
            })
            const staffUser = this.getData
            //Copy staffUser to formData but only the items that exist in formData
            Object.keys(this.form.data).forEach((key) => {
                if (this.dateFields.includes(key)) {
                    if (staffUser[key]) {
                        formData[key] = new Date(staffUser[key]).toISOString().substr(0, 10)
                    }

                    return
                }

                formData[key] = staffUser[key]
            })

            this.form.data = formData
            this.form.ready = true
            return
        }

        this.form.data.schoolYear = formatting.currentSchoolYear()
        this.form.ready = true
    },
    methods: {
        ...mapActions({
            getSites: 'site/get',
            getStaff: 'staff/get',
        }),
        async submit() {
            const data = {
                ...this.form.data,
            }
            this.dateFields.forEach((field) => {
                data[field] = formatting.isoDate(data[field])
            })

            const dispatchType = this.isEdit ? 'staff/patch' : 'staff/post'
            const payload = {
                lea: this.getDistrictLea,
                data: data,
            }
            if (this.isEdit) {
                payload.id = this.getData.id
            }

            await this.$store.dispatch(dispatchType, payload)

            const parentRoute = this.$router.resolve({
                params: { districtId: this.getDistrictId },
                name: 'user-management',
            })

            if (this.getErrors.length) {
                const errors = this.getErrors[0] || null

                this.form.errors = errors.details
            } else {
                await formSnackbar({
                    isEdit: this.isEdit,
                    type: 'User',
                    identifier: data.emailAddress,
                })
                await this.$router.push(parentRoute.href)
            }
        },
    },
}
</script>
